import {
	closestOptional, qsaOptional,
	qsOptional,
	qsRequired,
	targetClosestOptional,
	targetRequired
} from "@/scripts/core/global";
import { removeTrapFocus, trapFocus } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { HeaderMenu } from "@/scripts/theme/header-menu";

export class SearchModal extends UcoastEl {
	static htmlSelector = 'search-modal'
	detailsContainer: HTMLDetailsElement
	summaryToggle: HTMLElement
	button: HTMLButtonElement
	onBodyClickEvent?: (event: MouseEvent) => undefined
	megaMenus?: NodeListOf<HeaderMenu>
	constructor() {
		super()
		this.detailsContainer = qsRequired('details', this)
		this.summaryToggle = qsRequired('summary', this)
		this.button = qsRequired('button[type="button"]', this)
		this.megaMenus = qsaOptional('header-menu')

		this.detailsContainer.addEventListener(
			'keyup',
			(event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
		)
		this.summaryToggle.addEventListener('click', this.onSummaryClick.bind(this))
		this.button.addEventListener('click', this.close.bind(this))

		this.summaryToggle.setAttribute('role', 'button')


	}

	isOpen() {
		return this.detailsContainer.hasAttribute('open')
	}

	onSummaryClick(event: MouseEvent) {
		event.preventDefault()
		const closestDetails = targetClosestOptional(event, 'details')
		console.log('a click')
		if (closestDetails?.hasAttribute('open')) {
			this.close()
		} else {
			this.open({
				target: targetRequired(event),
			})
		}
	}

	onBodyClick(event: MouseEvent) {
		const target = targetRequired(event)
		if (!this.contains(target) || target.classList.contains('modal-overlay')) this.close(false)
	}

	open(event: { target: HTMLElement }) {
		this.megaMenus?.forEach((menu) => menu.close())
		this.classList.add('open')
		this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this)
		if (!this.onBodyClickEvent) throw new Error('onBodyClickEvent is undefined')
		const closestDetails = closestOptional(event.target, 'details')
		if (closestDetails) {
			closestDetails.setAttribute('open', '')
		}

		document.body.addEventListener('click', this.onBodyClickEvent)
		document.body.classList.add('overflow-hidden')

		trapFocus(
			qsRequired('[tabindex="-1"]', this.detailsContainer),
			qsOptional('input:not([type="hidden"])', this.detailsContainer)
		)
	}

	close(focusToggle = true) {
		this.classList.remove('open')
		removeTrapFocus(focusToggle ? this.summaryToggle : undefined)
		this.detailsContainer.removeAttribute('open')
		if (this.onBodyClickEvent) {
			document.body.removeEventListener('click', this.onBodyClickEvent)
		}

		document.body.classList.remove('overflow-hidden')
	}
}
